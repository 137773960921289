
function Error() {
	return(
		<section className="section-error">
		Oops! That page can’t be found
		</section>

		);

}
export default Error;